.home-container {
    text-align: center;
    padding: 60px 20px;
    max-width: 900px;
    margin: 0 auto;
    background-color: #f5f5f5;
    border-radius: 12px;
    box-shadow: 0 6px 12px rgba(0, 0, 0, 0.1);
    font-family: 'Arial', sans-serif;
}

.home-container h1 {
    margin-bottom: 30px;
    font-size: 3rem;
    font-weight: bold;
    color: #343a40;
    text-transform: uppercase;
}

.home-container h6 {
    font-size: 1.5rem;
    color: #6c757d;
    line-height: 1.6;
}

.home-container div {
    margin-bottom: 40px;
}

.home-container p {
    font-size: 1.2rem;
    margin-bottom: 15px;
}

.home-container .btn-container {
    display: flex;
    justify-content: center;
    gap: 15px;
}

.home-container a {
    text-decoration: none;
    font-weight: bold;
    padding: 12px 25px;
    border-radius: 8px;
    transition: background-color 0.3s, color 0.3s;
    font-size: 1.1rem;
}

.home-container a.login-btn {
    color: #fff;
    background-color: #007bff;
    border: 1px solid #007bff;
}

.home-container a.login-btn:hover {
    background-color: #0056b3;
}

.home-container a.signup-btn {
    color: #fff;
    background-color: #28a745;
    border: 1px solid #28a745;
}

.home-container a.signup-btn:hover {
    background-color: #218838;
}
