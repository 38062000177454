.add-entry-modal {
    background-color: #f8f9fa;
    border-radius: 5px;
    max-width: 950px !important;
}

.add-entry-modal h2 {
    margin-bottom: 20px;
}

.add-entry-modal .form-group {
    margin-bottom: 15px;
}

.add-entry-modal .btn {
    margin-right: 10px;
}
