/* src/styles/Preferences.css */
.preferences-container {
    display: flex;

    .preferences-sidebar {
        width: 200px;
        border-right: 1px solid #ccc;
    }
    
    .preferences-sidebar ul {
        list-style: none;
        padding: 0;
    }
    
    .preferences-sidebar li {
        padding: 10px;
        cursor: pointer;
    }
    
    .preferences-sidebar li:hover {
        background-color: #f0f0f0;
    }
    
    .preferences-content {
        padding: 20px;
        flex-grow: 1;
    }
    
    .session-timeout-indicator {
        margin-top: 20px;
        font-weight: bold;
        font-size: 1.2em;
    }
    
    .btn-custom {
        width: 165px !important;
        margin-left: 10px;
    }
}


