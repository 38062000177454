/* Dashboard.css */

.master-password-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    /* height: 100vh; */
    background-color: #f8f9fa;
    padding: 20px;
    /* border-radius: 10px; */
    border-style: outset;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    margin: auto 25px 25px;
}

.master-password-container h3 {
    margin-bottom: 20px;
    color: #343a40; /* Dark text color */
}

.master-password-container .form-control {
    max-width: 300px; /* Set max width */
    margin-bottom: 10px;
}

.master-password-container .btn {
    width: 100%; /* Full width button */
    max-width: 300px; /* Set max width */
    margin-bottom: 10px;
}

.master-password-container .note {
    max-width: 65%;
    text-align: center;
    color: darkred;
}

.hide-element {
    display: none;
}

.btn-custom {
    margin-right: 25px;
}

.session-expiry-label {
    color:red;
    font-weight: bolder;
}
