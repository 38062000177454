.sidebar-link {
    cursor: pointer;
    transition: background-color 0.3s;
}

.sidebar-link:hover {
    background-color: #f0f0f0;
}

.hide-on-mobile {
    @media (max-width: 375px) {
        display: none !important;
    }
}

.sidebar-link .btn-link {
    text-decoration: none;
    color: inherit;
}

.sidebar-link .btn-link:focus {
    outline: none;
}

