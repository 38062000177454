/* Banner.css */
.banner {
    background-color: #ffcc00;
    color: #000;
    padding: 10px;
    text-align: center;
    width: 100%;
    top: 0;
    z-index: 1000;
    font-size: 16px;
    font-weight: bold;
}
