.header-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1rem;
  background-color: #343a40;
  color: #fff;

  .logo-block {
    display: flex;
    align-items: center;

    .logo {
      height: 40px;
      margin-right: 1rem;
    }

    .site-title {
      font-size: 1.5rem;
      text-decoration: none;
      color: #fff;
    }
  }

  .nav-section {
    display: flex;
    align-items: center;

    nav {
      display: flex;

      .nav-link {
        margin-left: 1rem;
        text-decoration: none;
        color: #fff;
        font-size: 1rem;
      }
    }

    @media (max-width: 768px) {
      display: none;

      &.open {
        display: block;
        top: 60px;
        right: 0;
        background-color: #343a40;
        width: 100%;
        text-align: right;

        nav {
          display: block;

          .nav-link {
            display: block;
            padding: 1rem;
            border-bottom: 1px solid #444;
          }
        }
      }
    }
  }

  .mobile-nav-toggle {
    display: none;
    font-size: 1.5rem;
    cursor: pointer;

    @media (max-width: 768px) {
      display: block;
    }
  }
}
