/* About.css */
.about-container {
    padding: 20px;
    max-width: 800px;
    margin: 0 auto;
    background-color: #fff;
    color: #333;
    font-family: Arial, sans-serif;
  }
  
  .about-container h1 {
    text-align: center;
    font-size: 2.5em;
    margin-bottom: 20px;
  }
  
  .about-container p {
    text-align: center;
    font-size: 1.2em;
    margin-bottom: 20px;
  }
  
  .features h2 {
    font-size: 1.5em;
    margin-bottom: 15px;
  }
  
  .features ul {
    list-style: none;
    padding: 0;
  }
  
  .features ul li {
    margin-bottom: 10px;
    font-size: 1.1em;
  }
  
  .features ul li strong {
    font-weight: bold;
  }
  